import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, Sidebar, Body, Footer, } from '../../../components/layouts'

import {
	Card,
	Link,
	Image,
} from '../../../components/ui'

import Layout from '../../../components/layout'
import theme from '../../../theme/content'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
		}
	}


	render() {
		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
								<Card padding="1em" minHeight={'100%'}>
									<Container column >
										<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
											<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Did you know, texts are 21x more effective than email?</Heading>
										</Flex>
										<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
											<Image
												height="250px"
												width="auto"
												src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding1%402x.png'}/>
										</Flex>
										<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
											<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">
												<Link style={{margin: 'auto', width: '100%'}} href={"https://app.texting.roundupapp.com"} target={"_blank"}>Click here to start sending messages now</Link>
											</Heading>
											<Paragraph><Link style={{margin: 'auto', width: '100%'}} href={"https://app.texting.roundupapp.com"} target={"_blank"}>https://app.texting.roundupapp.com</Link></Paragraph>
											{this.props.organization && this.props.organization.id === 151 && <Paragraph><strong>Username:</strong> houstonFoodBank <strong>Password:</strong> h0ust0nF00d</Paragraph>}
										</Flex>
									</Container>
								</Card>
							</Flex>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	organization: PropTypes.object,
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization
	}
}

export default connect(mapStateToProps, null)(IndexPage)
